import {
	addToDataLayer,
	getGclid,
	getCSRFTokenFromCookie,
} from "@website-builder/utilities/utils/utils.js";
import {
	BUTTON_INTERACTION,
	FAILED_EVENTS,
	CTA_TYPES,
	GTM_EVENT_TIMEOUT,
	WAIT_TIME_FOR_ANALYTICS,
} from "@website-builder/utilities/constants/click-events.js";
import { getCloudinaryUrl } from "@website-builder/utilities/utils/CloudinaryUrlGenerator.js";
import { GetReferrer } from "@website-builder/utilities/utils/GetReferrer.js";
import { getSegmentAnonymousId } from "@website-builder/utilities/utils/SegmentEvents.js";
import { getUniqueUserId } from "@website-builder/utilities/utils/uniqueId.js";
import {
	getVwoCampaignId,
	getVwoCampaignIdFromWindow,
} from "@website-builder/utilities/utils/VWO.js";
import { LEAD_INTERACTION_REFERRAL_API_ENDPOINT } from "@website-builder/utilities/constants/urls.js";
import { GetReferrerUtmParams } from "@website-builder/utilities/utils/GetReferrerUtmParams.js";

export const scrollToElement = (id, margin = 50) => {
	const $elementWithId = document.querySelector(
		`#${id}:not([style*="display: none"])`,
	);
	if ($elementWithId) {
		window.requestAnimationFrame(() => {
			const yCoordinate =
				$elementWithId.getBoundingClientRect().y + window.scrollY;
			window.scrollTo({
				top: yCoordinate - margin,
				left: 0,
				behavior: "instant",
			});
		});
	}
	if (id === "apply-now" && $elementWithId) {
		const inputElement = $elementWithId?.querySelector("input"); // scroll to first focusable input element
		if (inputElement) {
			inputElement.focus();
		}
	}
};

export const isElementInViewport = (rect) => {
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.outerWidth || document.documentElement.clientWidth)
	);
};

export const getAnchorStyles = (darkBg = false) => {
	// const isB2U = isBusinessVerticalB2U();
	// return `
	//   a, a *, a:visited * {
	//     text-decoration: underline;
	//     &:hover {
	//         color: ${ darkBg ? "var(--secondary-purple-100)" : "var(--theme-primary, var(--secondary-purple-300))" };
	//     }
	//   }
	//   ${ darkBg && `
	//       ul {
	//           color: var(--theme-light-font-color, var(--primary-neutral-white));
	//       }
	//   `}
	// `;
	/**
	 * This function is meant to change the styles of the anchorStyles of the richtext wrapper. Currently
	 * commenting this as we will not be requiring the change.
	 */
	return "";
};

export const getColor = (type) => {
	switch (type) {
		case "default":
			return "#FFFFFF";
		case "primary":
			return "var(--theme-primary)";
		case "secondary":
			return "var(--theme-secondary)";
		default:
			return "";
	}
};
export const formatColor = (color) => {
	if (typeof color === "string") {
		return color;
	}
	if (
		color?.plugin === "springboard-color-picker" ||
		color?.type === "custom"
	) {
		return color?.color;
	}
	return getColor(color?.type);
};
export const getBluryAndLazyImageUrl = (backgroundImageUrl) => {
	const [, cloudName, , source, version, ...pathParts] = backgroundImageUrl
		.replace(/https:\/\/|http:\/\//gi, "")
		.split("/");

	const imagePath = pathParts.join("/").replace(/.webp|.jpg/g, ".png");

	const imageOptions = {
		quality: "auto",
		dpr: "auto",
		fetchFormat: "auto",
		version,
		source,
		cloudName,
	};
	const lazyImageUrl = getCloudinaryUrl(imagePath, imageOptions);
	const bluryImageUrl = getCloudinaryUrl(imagePath, {
		...imageOptions,
		width: 30,
		flags: "lossy",
	});

	return {
		bluryImageUrl,
		lazyImageUrl,
	};
};

export const openTypeFormURLWithParameters = (
	value,
	email,
	uuid,
	target = "_self",
	additionalParams,
) => {
	const campaignVariationIdStr = getVwoCampaignId();
	const [referral, referral2] = GetReferrer();
	const referrerUtmParams = GetReferrerUtmParams();
	const gclid = getGclid();

	let urlWithParams = new URL(value);

	const params = new URLSearchParams({
		uuid,
		referral,
		referral2,
		email,
		segmentanonymousid: getSegmentAnonymousId(),
		...(campaignVariationIdStr && { variationid: campaignVariationIdStr }),
		...(gclid && { gclid }),
		...referrerUtmParams,
		...additionalParams,
		...Object.fromEntries(urlWithParams.searchParams),
	}).toString();

	let typeformUrl = `${urlWithParams.origin}${urlWithParams.pathname}?&${params}`;
	window.open(typeformUrl, target);
};

const redirectToLink = (link, uuid, target = "_blank") => {
	const [referral, referral2] = GetReferrer();
	const gclid = getGclid();
	const campaignVariationIdStr = getVwoCampaignId();
	let urlWithParams = new URL(link);
	const params = new URLSearchParams({
		uuid,
		referral,
		referral2,
		segmentanonymousid: getSegmentAnonymousId(),
		...(campaignVariationIdStr && { variationid: campaignVariationIdStr }),
		...(gclid && { gclid }),
		...Object.fromEntries(urlWithParams.searchParams),
	}).toString();
	let redirectionURL = `${urlWithParams.origin}${urlWithParams.pathname}?&${params}`;

	const newTab = window.open(redirectionURL, target);
	newTab.focus();
};

const createRequest = (cta, apiData) => {
	const { context = {}, onSuccess, onError = () => {}, ...rest } = apiData;
	const {
		workshop_id,
		apiBaseUrl,
		typeformLink,
		slug,
		springformId,
		dripTagPrefix,
	} = context;

	const uuid = getUniqueUserId();
	const VWO_CAMPAIGN_DATA = getVwoCampaignIdFromWindow();
	const apiEndPoint = `${apiBaseUrl}/learning-paths/interested/`;
	const csrfTokenValue = getCSRFTokenFromCookie();

	const returnData = {
		cta: {
			link: cta?.link || {},
		},
		apiRequest: {
			url: apiEndPoint,
			headers: {
				"x-csrftoken": csrfTokenValue,
			},
			data: {},
			callbackOnSuccess: () => {},
			...rest,
		},
	};

	if (cta?.event === BUTTON_INTERACTION.APPLICATION_START) {
		//create CTA object
		returnData.cta.link["type"] = CTA_TYPES.typeform;
		returnData.cta.link["link"] = typeformLink;
		returnData.cta.link["target"] = "_self";

		//create api request data
		let formData = {
			apply: 1,
			js_disabled: "yes",
			workshop_id,
			drip_tag_prefix: dripTagPrefix,
			uuid: uuid,
		};

		if (!workshop_id) {
			returnData.apiRequest.url = `${apiBaseUrl}/learning-paths/interested/course_independent/`;
			formData = {
				lead_type: "GE",
				csrfmiddlewaretoken: csrfTokenValue,
			};
		}
		returnData.apiRequest.data = formData;
		returnData.apiRequest.callbackOnSuccess = () => {
			openTypeFormURLWithParameters(
				typeformLink,
				apiData?.params?.email,
				uuid,
				cta?.link?.target,
			);
			onSuccess?.();
		};
	}

	if (cta?.event === BUTTON_INTERACTION.SYLLABUS_DOWNLOAD) {
		//create CTA object
		returnData.cta.link["type"] = CTA_TYPES.syllabus;

		//create api request data
		const formData = {
			workshop_id,
			syllabus: 1,
			js_disabled: "yes",
			variation_id: VWO_CAMPAIGN_DATA,
			drip_tag_prefix: dripTagPrefix,
		};
		returnData.apiRequest.data = formData;
		returnData.apiRequest.callbackOnSuccess = () => {
			onSuccess?.();
		};
		returnData.apiRequest.callbackOnError = () => {
			onError?.();
		};
	}

	if (cta?.event === BUTTON_INTERACTION.APPLICATION_START_WAIT_LIST) {
		returnData.cta.link["type"] = CTA_TYPES.typeform;
		//create api request data
		let formData = {
			js_disabled: "yes",
			workshop_id,
			drip_tag_prefix: "WL",
			uuid: uuid,
		};
		returnData.apiRequest.data = formData;
		returnData.apiRequest.callbackOnSuccess = () => {
			const doRedirect = onSuccess?.();
			if (doRedirect)
				openTypeFormURLWithParameters(
					typeformLink,
					apiData?.params?.email,
					uuid,
					cta?.link?.target,
				);
		};
	}

	if (cta?.event === BUTTON_INTERACTION.QUIZ_START) {
		returnData.apiRequest.url = `${apiBaseUrl}${LEAD_INTERACTION_REFERRAL_API_ENDPOINT}`;
		const [referral, referral2] = GetReferrer();

		const body = {
			uuid: uuid,
			referral: referral,
			referral2: referral2,
		};

		returnData.apiRequest.data = body;
		returnData.apiRequest.callbackOnSuccess = () => {
			redirectToLink(cta?.link?.link, uuid, cta?.link?.target);
			onSuccess?.();
		};
	}

	return returnData;
};

const apiEvents = [
	BUTTON_INTERACTION.APPLICATION_START,
	BUTTON_INTERACTION.APPLICATION_START_WAIT_LIST,
	BUTTON_INTERACTION.SYLLABUS_DOWNLOAD,
	BUTTON_INTERACTION.QUIZ_START,
];

export const handleCTAClick = (
	ctaRaw,
	eventDataRaw,
	apiData = {},
	clickEvent,
	optionalParams = {},
) => {
	//Call Analytics Event based on the buttonEvent
	let eventName = ctaRaw?.event;

	//Call Analytics Event for Button Click based on the buttonEvent
	if (eventName === BUTTON_INTERACTION.APPLICATION_START) {
		addToDataLayer({
			event: "application_start_click",
			...eventDataRaw,
		});
	} else if (eventName === BUTTON_INTERACTION.SYLLABUS_DOWNLOAD) {
		addToDataLayer({
			event: "syllabus_download_click",
			...eventDataRaw,
		});
	}

	const eventData = {
		...eventDataRaw,
		event: eventName,
	};

	if (
		eventName !== BUTTON_INTERACTION.NONE &&
		eventName !== BUTTON_INTERACTION.APPLICATION_START &&
		eventName !== BUTTON_INTERACTION.APPLICATION_START_WAIT_LIST &&
		eventName !== BUTTON_INTERACTION.SYLLABUS_DOWNLOAD &&
		eventName !== BUTTON_INTERACTION.QUIZ_START
	) {
		addToDataLayer(eventData);
	}

	let ctaData = ctaRaw;
	let apiRequestData = {};
	// Handle CTA Click based on type
	if (apiEvents.includes(ctaRaw?.event)) {
		const { cta, apiRequest } = createRequest(ctaRaw, apiData);
		ctaData = cta;
		apiRequestData = apiRequest;
	}
	// Handle CTA Click based on type
	let type = ctaData?.link?.type;
	let value = ctaData?.link?.link;
	let target = ctaData?.link?.target;

	if (clickEvent?.ctrlKey || clickEvent?.metaKey) {
		// ctrlKey - ctrl key on windows
		// metaKey - cmd key on Mac
		target = "_blank";
	}

	switch (type) {
		case "hash":
			scrollToElement(value);
			break;
		case "typeform":
			postRequest(apiRequestData, eventData);
			break;
		case "external":
			clickEvent?.preventDefault();
			window.open(value, target);
			break;
		case "internal":
			clickEvent?.preventDefault();
			window.open(value, target);
			break;
		// SPECIAL CASE ADDED TO HANDLE REQUEST_SYLLABUS CASE
		case "syllabus":
			if (optionalParams?.callbackOnError)
				apiRequestData.callbackOnError = optionalParams?.callbackOnError;
			postRequest(apiRequestData, eventData);
			break;
		case "modal":
			break;
		default:
			console.error("CTA Type or Event is not defined for", type);
	}
};

const postRequest = (apiRequest, eventData) => {
	const {
		url,
		data: formData = {},
		callbackOnSuccess,
		callbackOnError,
		headers = {},
	} = apiRequest;
	// Create Form Data as body
	var body = new FormData();
	Object.keys(formData).forEach((key) => {
		body.append(key, formData[key]);
	});

	if (apiRequest["params"]) {
		const params = apiRequest["params"];
		Object.keys(params).forEach((key) => {
			body.append(key, params[key]);
		});
	}
	const fetchOptions = {
		method: "POST",
		body,
		referrerPolicy: "no-referrer-when-downgrade",
		credentials: "include",
	};
	// Conditionally add headers if not empty
	fetchOptions.headers = Object.keys(headers).length > 0 ? { ...headers } : {};
	fetch(url, fetchOptions)
		.then((res) => res.json())
		.then((response) => {
			const { result = "", success = false } = response;

			if (result === "success" || success) {
				if (!!window["google_tag_manager"]) {
					addToDataLayer({
						...eventData,
						eventCallback: function (id) {
							if (id === process.env.GATSBY_GTM_CONTAINER_ID) {
								setTimeout(
									onSuccess,
									WAIT_TIME_FOR_ANALYTICS,
									callbackOnSuccess,
								);
							}
						},
						eventTimeout: GTM_EVENT_TIMEOUT,
					});
				} else {
					console.error("GTM DOESN'T EXIST!");
					onSuccess(callbackOnSuccess);
				}
			} else {
				onError(callbackOnError);
				addToDataLayer({
					...eventData,
					event: FAILED_EVENTS[eventData.event],
				});
			}
		})
		.catch((err) => {
			onError(callbackOnError);
			console.error("Error: ", err);
			addToDataLayer({
				...eventData,
				event: FAILED_EVENTS[eventData.event],
			});
		});
};

const onSuccess = (callbackOnSuccess) => {
	callbackOnSuccess?.();
};

const onError = (callbackOnError) => {
	callbackOnError?.();
};
